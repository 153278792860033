import { Component, HostListener, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from "../../dialog/confirm-dialog/confirm-dialog.component"
import { PauseDialogComponent } from "../../dialog/pause-dialog/pause-dialog.component"

import { PlayService } from "../../service/play.service";
import { StatusService } from 'src/app/service/status.service';
import { SettingService } from 'src/app/service/setting.service';
import { CommunityService } from 'src/app/service/community.service';
import { MMDService } from 'src/app/service/mmd.service';

import { GUI } from 'dat.gui'
import { animate } from '@angular/animations';
import { NetworkService } from 'src/app/service/network.service';

import * as Tone from 'tone';
import { Midi } from '@tonejs/midi'

declare let THREE: any;
declare let Ammo: any;

let noteColor: Array<string> = [];
let noteColorDark: Array<string> = [];
let noteColorBright: Array<string> = [];
let effectColor: Array<string> = [];


interface KeyLog {
  k: number;  // key
  p: number;  // press
  t: number;  // time
}
interface ScoreLog {
  k: number;  // answerType
  s: number;  // score
  t: number;  // time
  l: number;  // life
}
interface LineSetting {
  key: string;
  subkey: string;
  color: string;
  effect: string;
}

interface GameLog {
  answerLine: number;
  noteSpeed: number;
  difficult: number;
  isShuffle: boolean;
  isFade: boolean;
  lines: Array<LineSetting>;
  history: {
    score: Array<ScoreLog>
    key : Array<KeyLog>
  }
}

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit {
  private keyMap: Array<string> = [];
  private subkeyMap: Array<string> = [];
  private keyShuffle: Array<number> = [];
  private longNoteSwitch: Array<number> = [];
  public effectMap: Array<any> = [];
  public midiKeys: Array<any> = ['C3','D3','E3','F3','G3','A3','B3',];
  public currentReaction: Array<any> = [];  
  public init_press: boolean = false;
  
  public gameId: string = '';
  public title: string = '';
  public artist: string = '';
  public cover: string = '';
  public coverImage: string | undefined = undefined;
  public thumbnailImage: string = '';
  public audioURL: string = '';
  public videoURL: string | undefined = undefined;
  public timebarWidth: number = 0;
  public countdown: number = -1;
  
  private pauseAnimationHandle: any = undefined;
  public isPaused: boolean = true;
  private isDisplayMMD: boolean = false;
  private isDisplayMV: boolean = false;
  private isDisplayBgImg: boolean = false;
  private isShuffle: boolean = false;
  private isFade: boolean = false;
  private isDisplayEffect: boolean = false;
  private isDisplayHitEffect: boolean = false;
  private isDisplayKeyPressEffect: boolean = false;
  
  private onPaintHandle: any = undefined;
  private recordedNotes: Array<any> = [];
  private reactions: Array<any> = [];
  private reactionIdx: number = 0;  
  private noteIdx: number = 0;
  private noteWidth: number = 80;
  private noteSpeed: number = 1500;
  private notes: Array<Array<any>> = [];
  private downNotePerSecond: number = 0;
  private downNotePivot: number = 0;
  public clientHeight: number = 0;
  public score: number = 0;
  public scorePrint: string = '0';
  private answerLine = 0.85;
  public answerLinePer = this.answerLine * 100;
  public keyPress: boolean = false;
  private cheat: boolean = false;
  
  private mid: string = '';
  public combo: any = {};
  public setting: any = {}
  public loading: any = {};

  private gamelog: GameLog = {
    answerLine: 0,
    noteSpeed: 0,
    difficult: 0,
    isShuffle: false,
    isFade: false,
    lines: [],
    history: {
      score: [],
      key : [],
    }
  };
 
  private threeMMD: any = {}
  
  @ViewChild('noteCanvas') noteCanvas: ElementRef = {} as ElementRef;
  @ViewChild('visualizationCanvas') visualizationCanvas: ElementRef = {} as ElementRef;
  @ViewChild('audio') audioSelector: ElementRef = {} as ElementRef;
  @ViewChild('video') videoSelector: ElementRef = {} as ElementRef;


  private audio: any = {};
  private video: any = {};
  
  public hitEffectImage: any = undefined;
  public hitEffectImage2: any = undefined;
  
  private context: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  private visualizationContext: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  private visualizerHandle: any = undefined;
  private synth!: Tone.PolySynth;
  private piano!: Tone.Sampler;
  
  constructor(
    private zone: NgZone,
    private router: Router, 
    private route: ActivatedRoute,
    private location: LocationStrategy,
    public dialog: MatDialog,
    public status: StatusService,
    public playService: PlayService,
    public communityService: CommunityService,
    public settingService: SettingService,
    public networkService : NetworkService,
    public MMD: MMDService,
  ) { 
    this.mid = route.snapshot.params['mid'];
    
    history.pushState(null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, window.location.href);
    });

    // setInterval(() => {
    //   this.noteSpeed = (Math.floor(Math.random() * 4.9) + 2) * 500;
    //   this.onResize(null);
    // }, 5000)
  }

  ngOnInit(): void {
    this.status.sidebar = false;
    this.clientHeight = +(window.innerHeight || 0);
    this.init();
  }

  ngAfterViewInit(): void {
    this.context = this.noteCanvas.nativeElement.getContext('2d');
    this.context.canvas.width = this.notes.length * this.noteWidth;
    this.context.canvas.height = this.clientHeight;
    
    
    this.hitEffectImage = document.getElementById("effect")
    this.hitEffectImage2 = document.getElementById("effect2")
  }
  ngOnDestroy(): void {
    this.status.sidebar = true;
    
    if (this.threeMMD.audio?.isPlaying) {
      this.threeMMD.audio?.stop();
    }
    if (this.onPaintHandle) {
      cancelAnimationFrame(this.onPaintHandle);
      this.onPaintHandle = undefined;
    }
    
    cancelAnimationFrame(this.visualizerHandle);
    cancelAnimationFrame(this.threeMMD.fid);
  }
  
  public onKeyTouch(idx: number){
    this.onKeydown({code : this.keyMap[idx]})
    this.onKeyup({code : this.keyMap[idx]})
  }
  public onKeyup(event: any) {
    let idx = this.keyMap.indexOf(event.code);

    if (this.status.isOpenDialog) {
      return;
    }
    
    this.init_press = true;

    if (idx < 0) {
      idx = this.subkeyMap.indexOf(event.code);

      if (idx < 0) {
        return;
      }
    }

    this.synth?.triggerRelease(this.midiKeys[idx]);
    this.effectMap[idx].press = false;
    this.gamelog.history.key.push({k: idx, p: 0, t: this.audio.context.currentTime});
    this.effectMap[idx].keyPressPivot = new Date().getTime();
    this.networkService.onKeyEvent(event);
  }
  public onKeydown(event: any) {
    let idx = this.keyMap.indexOf(event.code);
    let validIdx = 0;
    
    if (this.status.isOpenDialog) {
      return;
    }

    switch(event.code) {
      case 'Escape':
        // this.isPaused && !this.pauseAnimationHandle ? this.play() : this.pause();
        this.networkService.onKeyEvent(event);
        this.onLeave({});
        break;
      case 'KeyZ':
        this.networkService.onKeyEvent(event);
        this.cheat = !this.cheat;
        break;
    }

    if (idx < 0) {
      idx = this.subkeyMap.indexOf(event.code);

      if (idx < 0) {
        return;
      }
    }
    
    if(this.effectMap[idx].press)
      return;
    
    this.synth?.triggerAttack(this.midiKeys[idx]);
    this.effectMap[idx].press = true;
    this.gamelog.history.key.push({k: idx, p: 1, t: this.audio.context.currentTime});
    
    for (; validIdx < this.notes[idx]?.length; validIdx++) {
      if ((this.notes[idx][validIdx].longnote || 0) >= 0)
        break;
    }

    if (validIdx >= this.notes[idx].length) {
      return;
    }

    this.networkService.onKeyEvent(event);
    this.answer_check(idx, validIdx);
  }
  public onKeypress(event: any) {
    let idx = this.keyMap.indexOf(event.code);
    
    if (idx < 0 || !this.notes[idx].length)
      return;
    
    let score = (20 - Math.pow(Math.abs(this.answerLine - (this.notes[idx][0].y / this.clientHeight)) * 10, 1.5));
      
    if(score > 0) {
      this.combo.count += 1;
      this.combo.lastPivot = new Date().getTime();
      this.score += score * Math.log(this.combo.count + 1);
      this.scorePrint = this.score.toFixed(0);

      this.notes[idx].splice(0, 1);
      this.combo.lastJudge = 'Perfect!!';
      this.combo.lastClass = 'perfect';
    }
  }
  public onResize(event: any){
    this.clientHeight = +(window.innerHeight || 0);
    this.downNotePerSecond = (this.clientHeight * this.answerLine * 1000) / this.noteSpeed;

    if(this.context?.canvas) {
      this.context.canvas.width = this.notes.length * this.noteWidth;
      this.context.canvas.height = this.clientHeight;
    }    
  }


  public init() {
    if (!this.settingService.setting.init) {
      this.settingService.get_setting();

      setTimeout(() => {
        this.init();
      }, 500);
    }
    else {
      this.init_variable();
      this.init_setting();
      this.init_background();
      this.onResize(null);
      
      for (let i = 0; i < this.keyMap.length; i++) {
        this.notes.push([]);
        this.longNoteSwitch.push(0);
        this.keyShuffle.push(i);
      }

      if (this.isShuffle) {
        this.keyShuffle.sort((a, b) => {return Math.random() - 0.5;});
      }
      
      this.context = this.noteCanvas.nativeElement.getContext('2d');
      this.context.canvas.width = this.notes.length * this.noteWidth;
      this.context.canvas.height = this.clientHeight;
    }
  }
  public init_variable() { 
    this.keyMap = [];
    this.subkeyMap = [];
    this.keyShuffle = [];
    this.longNoteSwitch = [];
    this.effectMap = [];
    
    this.audioURL = '';
    this.videoURL = undefined
    this.timebarWidth = 0;
    this.countdown = -1;
    this.isPaused = true;
    
    console.log('call init_variable');
    console.log(this.notes);

    cancelAnimationFrame(this.visualizerHandle);
    cancelAnimationFrame(this.threeMMD.fid);
    cancelAnimationFrame(this.pauseAnimationHandle);
    cancelAnimationFrame(this.onPaintHandle);
      
    console.log('call init_variable - 2');
    this.pauseAnimationHandle = undefined;
    this.onPaintHandle = undefined;
  
    this.recordedNotes = [];
    this.noteIdx = 0;
    this.notes = [];
    this.score = 0;
    this.scorePrint = '0';
    this.keyPress = false;
    this.cheat = false;
    
    this.combo = {
      life: 100.0,
      maxCombo: 0,
      count: 0,
      perfect : 0,
      excellent : 0,
      good : 0,
      bad : 0,
      miss: 0,
      total: 0,
      lastJudge: '',
      lastClass: '',
      lastPivot: 0,
    };

    console.log('call init_variable - 3');
    
    this.loading = { 
      complete: false,
      list : {},
      keys : [],
    };
  
    this.threeMMD = {
      scene: undefined, 
      camera: undefined, 
      renderer: undefined, 
      audio: undefined,
      effect: undefined,
      helper : undefined,
      fid: undefined,
      ready : false,
      clock : undefined,
      physicsHelper: undefined,
      ikHelper: undefined,
    }

    this.audio = {
      paused : undefined,
      duration : undefined,
      context : undefined,
      play : undefined,
      pause : undefined,
      pivot : undefined,
    }
    this.video = {
      paused : undefined,
      play : undefined,
      pause : undefined,
    }
  }

  public init_setting() {
    this.setting = this.settingService.setting;
    this.noteSpeed = (this.setting.noteSpeed || 1.5) * 1000;
    this.answerLine = this.setting.answerLine || 0.85;
    this.answerLinePer = this.setting.answerLine * 100;
    this.isDisplayMMD = +this.setting.mmdDisplay != 0;
    this.isDisplayMV = +this.setting.videoDisplay != 0;
    this.isDisplayBgImg = +this.setting.bgImageDisplay != 0;
    this.isDisplayHitEffect = +this.setting.hitEffectDisplay != 0;
    this.isDisplayKeyPressEffect = +this.setting.keyPressEffectDisplay != 0;
    this.isDisplayEffect = this.isDisplayKeyPressEffect || this.isDisplayHitEffect;
    this.isShuffle = Math.floor(+this.setting.difficult) % 2 == 1;
    this.isFade = Math.floor(+this.setting.difficult / 2) == 1;
    
    const mixedColor = (color1: string, color2: string) => {
      let color = '#';
      let lengthColor1 = Math.floor(color1.length / 3);
      let lengthColor2 = Math.floor(color2.length / 3);
      
      color1 = color1.replace('#', '');
      color2 = color2.replace('#', '');
      
      for(let i = 0; i < 3; i++) {
        let hex1 = parseInt(color1.substring(i * lengthColor1, (i + 1) * lengthColor1), 16);
        let hex2 = parseInt(color2.substring(i * lengthColor2, (i + 1) * lengthColor2), 16);
        let code = (Math.floor((hex1 + hex2) / 2) % 255);

        color += ('00' + code.toString(16)).substr(-2);
      }
      

      return color;
    }
    if (this.setting.lines) {
      this.keyMap = [];
      this.effectMap = [];
      this.gamelog.lines = [];
      
      noteColor = [];
      noteColorDark = [];
      noteColorBright = [];
      effectColor = [];
      
      for (let i = 0; i < this.setting.lines.length; i++) {
        let line = this.setting.lines[i];  
        
        this.gamelog.lines.push(line);
        this.keyMap.push(line.key);
        this.subkeyMap.push(line.subkey);
        this.effectMap.push({
          key : line.key.replace('Key', ''), 
          keyPressPivot: 0,
          lastHitPivot: 0,
          press: false,
        });

        noteColor.push(line.color);
        noteColorDark.push(mixedColor(line.color, '#000000'));
        noteColorBright.push(mixedColor(line.color, '#FFFFFF'));
        effectColor.push(line.effect);
      }
    }
    else {
      console.log('no setting file');
    }


    this.gamelog.answerLine = this.answerLine;
    this.gamelog.noteSpeed = this.noteSpeed;
    this.gamelog.difficult = +this.setting.difficult;
    this.gamelog.isShuffle = this.isShuffle;
    this.gamelog.isFade = this.isFade;
    
  }

  public init_midi = async (midiURL: string | undefined, midiPadding: number = 0) => {
    this.piano = new Tone.Sampler({ 
			urls: {
				A0: "A0.mp3",
				C1: "C1.mp3",
				"D#1": "Ds1.mp3",
				"F#1": "Fs1.mp3",
				A1: "A1.mp3",
				C2: "C2.mp3",
				"D#2": "Ds2.mp3",
				"F#2": "Fs2.mp3",
				A2: "A2.mp3",
				C3: "C3.mp3",
				"D#3": "Ds3.mp3",
				"F#3": "Fs3.mp3",
				A3: "A3.mp3",
				C4: "C4.mp3",
				"D#4": "Ds4.mp3",
				"F#4": "Fs4.mp3",
				A4: "A4.mp3",
				C5: "C5.mp3",
				"D#5": "Ds5.mp3",
				"F#5": "Fs5.mp3",
				A5: "A5.mp3",
				C6: "C6.mp3",
				"D#6": "Ds6.mp3",
				"F#6": "Fs6.mp3",
				A6: "A6.mp3",
				C7: "C7.mp3",
				"D#7": "Ds7.mp3",
				"F#7": "Fs7.mp3",
				A7: "A7.mp3",
				C8: "C8.mp3"
			},
			release: 1,
			baseUrl: "https://tonejs.github.io/audio/salamander/"
    }).toDestination();

    await Tone.start();
    console.log('Tone.js is ready');
    this.synth = new Tone.PolySynth().toDestination();

    if (midiURL){
      const midi = await Midi.fromUrl(midiURL)
      const name = midi.name
      
      console.log(`midi ${midi}`);
      
      midi.tracks[0].notes?.map((item)=> {
        item.time += midiPadding;
      })

      // 노트 이벤트를 스케줄링하는 Tone.Part 인스턴스 생성
      const part = new Tone.Part((time, note) => {
        this.synth.triggerAttackRelease(note.name, note.duration, time, note.velocity);
      }, midi.tracks[0].notes).start(0);

      // Tone.Transport 설정
      Tone.Transport.bpm.value = midi.header.tempos[0]?.bpm;
      Tone.Transport.pause();
      
      
      midi.tracks.forEach(track => {
        //tracks have notes and controlChanges
  
        //notes are an array
        const notes = track.notes
      
        // notes.forEach(note => {
        //   console.log(note);
        //   console.log(note.name, note.duration, note.time);
        //   this.synth?.triggerAttackRelease(note.name, note.duration, note.time + 3);
        //   Tone.Transport.pause();
        // })
  
        //the control changes are an object
        //the keys are the CC number
        // track.controlChanges[64]
        //they are also aliased to the CC number's common name (if it has one)
        
        //the track also has a channel and instrument
        //track.instrument.name
      })
    }
  }

  public init_background = () => {
    this.communityService.get_reaction(this.mid).subscribe((res: any) => {
      console.log(res);
      if(res.status){
        this.reactions = res.reaction;
        console.log(res.self);
      }
    })
    this.playService.get_music(this.mid).subscribe((res: any) => {
      if(res.status) {
        this.gameId = res.game_id;
        this.title = res.title;
        this.artist = res.artist;
        this.cover = res.cover;
        this.thumbnailImage = res.thumbnail;
        this.noteIdx = 0;
        
        if (!this.isDisplayMMD) {
          res.mmd = undefined;
        }
        if (!this.isDisplayMV) {
          res.video = undefined;
        }
        if (!this.isDisplayBgImg) {
          res.cover = undefined;
        }
        
        this.coverImage = res.cover;
        this.recordedNotes = res.notes;
        
        if (!res.mmd) {
          this.audioURL = res.audio;
          this.videoURL = res.video;
          this.downNotePivot = new Date().getTime();
          this.loading.complete = true;

          if (res.midi) {
            // this.init_midi(res.midi, res?.midi_padding || 0);
          }
        }
        else {
          const init = () => {
            this.threeMMD.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
            this.threeMMD.camera.position.x = 0;
            this.threeMMD.camera.position.y = 20;
            this.threeMMD.camera.position.z = 100;
            this.threeMMD.camera.lookAt(new THREE.Vector3(0, 15, 0));

            this.threeMMD.scene = new THREE.Scene();
            this.threeMMD.scene.background = new THREE.Color(0x000000);


            const listener = new THREE.AudioListener();
            this.threeMMD.camera.add(listener);
            this.threeMMD.scene.add(this.threeMMD.camera);

            // const ambient = new THREE.AmbientLight(0x555555);
            // const ambient = new THREE.AmbientLight(0x888888);
            
            // this.threeMMD.scene.add(ambient);
            

            
            const directionalLight = new THREE.DirectionalLight(0xcccccc);
            // const directionalLight = new THREE.DirectionalLight(0x887766);
            
            // directionalLight.position.set(-1, 1, 1).normalize();
            directionalLight.position.set(150, 150, 150);
            directionalLight.castShadow = true;
            directionalLight.shadow.mapSize.width = 5120;
            directionalLight.shadow.mapSize.height = 5120;
            // directionalLight.shadow.camera.near = 0.5;
            // directionalLight.shadow.camera.far = 500;

            this.threeMMD.scene.add(directionalLight);

            this.threeMMD.renderer = new THREE.WebGLRenderer({antialias: true});
            this.threeMMD.renderer.setPixelRatio(window.devicePixelRatio);
            this.threeMMD.renderer.setSize(window.innerWidth, window.innerHeight);
            this.threeMMD.renderer.shadowMap.enabled = true;
            
            document.getElementById('mmd-wrapper')?.appendChild(this.threeMMD.renderer.domElement);

            // # outline in mmd
            this.threeMMD.effect = new THREE.OutlineEffect(this.threeMMD.renderer);
            
            const onProgress = (xhr: any) => {
              if (xhr.lengthComputable) {
                const [filename] = xhr.target.responseURL.split('/').splice(-1);
                const percentComplete = xhr.loaded / xhr.total * 100;
                const bytes = ['Byte', 'KiB','MiB','GiB','TiB'];
                const loadedByteIdx = Math.floor(Math.log2(xhr.loaded) / 10);
                const totalByteIdx = Math.floor(Math.log2(xhr.total) / 10); 
                
                this.loading.list[xhr.target.__zone_symbol__xhrURL].percent = percentComplete;
                this.loading.list[xhr.target.__zone_symbol__xhrURL].size = 
                  `${(xhr.loaded / Math.pow(2, 10 * loadedByteIdx)).toFixed(2)} ${bytes[loadedByteIdx]} / ${(xhr.total / Math.pow(2, 10 * totalByteIdx)).toFixed(2)} ${bytes[totalByteIdx]}`
              }
            }


            // this.MMD.set_datGUI(this.threeMMD);


            const mmdFile = JSON.parse(res.mmd);
            const root_path = res.mmd_path || '../../../assets/models/mmd/'
            
            this.threeMMD.helper = new THREE.MMDAnimationHelper();

            let mmdLoader = new THREE.MMDLoader();
            let mmdModels = [];
            
            for (let i = 0; i < mmdFile.motion.length; i++) {
              let name: string = mmdFile.model ? mmdFile.model[i] : 'default';
              let motion: Array<string> = [root_path + mmdFile.motion[i]];
              let model: string = root_path + this.MMD.models[name][Math.floor(Math.random() * this.MMD.models[name].length)];
              
              if(mmdFile.face && mmdFile.face[i]){
                motion.push(root_path + mmdFile.face[i]);
              }
              
              this.loading.list[model] = {filename: model.split('/').splice(-1).join('/'), percent:0};
              
              for (let j = 0; j < motion.length; j++) {
                this.loading.list[motion[j]] = {filename: motion[j].split('/').splice(-1).join('/'), percent:0};
              }
              
              mmdModels.push({model: model, motion: motion});
            }

            const stagePath = '../../../assets/models/mmd/stage/椛暗式-遇见_耀ver1.0/遇见_耀ver1.0.pmx'
            // const stagePath = '../../../assets/models/mmd/stage1/stage.pmx';
            this.loading.list[stagePath] = {filename: 'stage.pmx', percent:0};
            this.loading.list[root_path + mmdFile.camera] = {filename: (root_path + mmdFile.camera).split('/').splice(-2).join('/'), percent:0};
            this.loading.list[root_path + mmdFile.audio] = {filename: (root_path + mmdFile.audio).split('/').splice(-2).join('/'), percent:0};

            this.loading.keys = Object.keys(this.loading.list);

            let load_model = (idx: number, mmdModels: Array<any>, mmdFile: any, callback: any) => {
              if (idx >= mmdModels.length)
                return callback();
              
              mmdLoader.loadWithAnimation(mmdModels[idx].model, mmdModels[idx].motion, (mmd : any) => {
                let mesh = mmd.mesh;

                this.threeMMD.helper.add(mesh, {
                  animation: mmd.animation,
                  physics: true,
                });
                
                mesh.traverse((child: any) => {
                  if (child instanceof THREE.Mesh) {
                    child.castShadow = true;    // 해당 메쉬가 그림자를 생성
                    child.receiveShadow = true; // 해당 메쉬가 그림자를 받음
                  }
                });

                this.threeMMD.scene.add(mesh);
                
                // this.MMD.set_ikHelper(this.threeMMD, mesh);
                // this.MMD.set_physicsHelper(this.threeMMD, mesh);
                return load_model(idx + 1, mmdModels, mmdFile, callback);
              }, onProgress, null);
            }
            
            load_model(0, mmdModels, mmdFile, () => {
              mmdLoader.load(stagePath, (object: any) => {
                object.traverse((child: any) => {
                  if (child instanceof THREE.Mesh) {
                    child.castShadow = true;    // 해당 메쉬가 그림자를 생성
                    child.receiveShadow = true; // 해당 메쉬가 그림자를 받음
                  }
                });

                this.threeMMD.scene.add(object);
        
                mmdLoader.loadAnimation(root_path + mmdFile.camera, this.threeMMD.camera, (cameraAnimation: any) => {
                  this.threeMMD.helper.add(this.threeMMD.camera, {
                    animation: cameraAnimation
                  });
                
                  new THREE.AudioLoader().load(root_path + mmdFile.audio, (buffer: any) => {
                    const duration = (buffer.duration + mmdFile.delayTime) * 1000;
                    
                    this.threeMMD.audio = new THREE.Audio(listener).setBuffer(buffer);
                    this.audio.duration = duration;
                    this.audio.context = this.threeMMD.audio?.context;
                    this.audio.play = this.threeMMD.audio.play.bind(this.threeMMD.audio.play);
                    this.audio.pause = this.threeMMD.audio.pause.bind(this.threeMMD.audio.pause);

                    console.log(this.audio);

                    this.video.play = () => {
                      this.threeMMD.helper.enable('cameraAnimation', true);
                      this.threeMMD.helper.enable('animation', true);
                      this.threeMMD.helper.enable('physics', true);
                    }
                    this.video.pause = () => {
                      this.threeMMD.helper.enable('cameraAnimation', false);
                      this.threeMMD.helper.enable('animation', false);
                      this.threeMMD.helper.enable('physics', false);
                    }

                    this.threeMMD.audio.setVolume(0.3);
                    // this.threeMMD.helper.add(this.threeMMD.audio, {delayTime : mmdFile.delayTime});
          
                    console.log('# init ThreeJS - - - - - - - - - - - -')
                    console.log(this.audioSelector);
                    console.log(this.threeMMD);
                    
                    // this.audioSelector = this.threeMMD.audio;
          
                    this.audioURL = res.audio;
                    this.downNotePivot = new Date().getTime();
                    this.threeMMD.clock = new THREE.Clock();
                    this.threeMMD.ready = true;
                    this.threeMMD.helper.update(mmdFile.delayTime);
                    this.loading.complete = true;
                    this.isPaused = false;

                    if(res.notes?.length) {
                      this.recordedNotes = res.notes;
                      this.noteIdx = 0;
                      console.log(res.notes);
                                
                      const animate = () => {
                        this.onPaintHandle = requestAnimationFrame(animate);
                        this.createNote();
                        this.downNote();
                      }
                      animate();
                    }
                    else{
                      const animate = () => {
                        this.onPaintHandle = requestAnimationFrame(animate);
                        this.randomCreate();
                        this.downNote();
                      }
                      animate();
                    }
                    
                    setTimeout(() => {
                      this.send_result();
                    }, duration)
                  }, onProgress, null);
                }, onProgress, null);
              }, onProgress, null);
            })
          }

          const onWindowResize = () => {
            this.threeMMD.camera.aspect = window.innerWidth / window.innerHeight;
            this.threeMMD.camera.updateProjectionMatrix();

            this.threeMMD.effect.setSize(window.innerWidth, window.innerHeight);
          }
          const animate = () => {
            this.threeMMD.fid = requestAnimationFrame(animate);
            render();
          }

          const render = () => {
            if (this.threeMMD.ready) {
              this.threeMMD.helper.update(this.threeMMD.clock.getDelta());
            }
            this.threeMMD.effect.render(this.threeMMD.scene, this.threeMMD.camera);
          }

          window.addEventListener('resize', onWindowResize);
        
          if (typeof(Ammo) == 'function') {
            Ammo().then(function (AmmoLib: any) {
              Ammo = AmmoLib;
              init();
              animate();
            });
          }
          else {
            init();
            animate();
          }
        }
      }
    })
  }


  public createNote() {
    const mapping: any = {
      0:0,  1:1,  2:2,  3:3, 4:4,  5:5,  6:6,
      7:0,  8:1,  9:2,  10:3, 11:4, 12:5, 13:6,
      14:0, 15:1, 16:2, 17:3, 18:4, 19:5, 20:6,
    }

    let time = this.audio.context.currentTime * 1000;
    let ts = time + this.noteSpeed;

    
    for (let i = 0; i < this.longNoteSwitch.length; i++){
      if (this.longNoteSwitch[i]) {
        this.notes[i].push({y: 0, noteId: this.longNoteSwitch[i], longnote: this.longNoteSwitch[i] > 0 ? 1 : -1});
      }
    }

    while (this.reactionIdx < this.reactions.length) {
      let reaction = this.reactions[this.reactionIdx];

      if (ts < reaction.msec) {
        break;
      }

      this.reactionIdx++;
      this.currentReaction.push({
        reaction : reaction.reaction,
        x : Math.random() * (window.innerWidth - this.setting.boardWidth) + this.setting.boardWidth,
        y : Math.random() * 100,
      })

      console.log(this.currentReaction);
    }

    while (this.noteIdx < this.recordedNotes.length) {
      let note = this.recordedNotes[this.noteIdx];
      
      if (ts < note.ts) {
        return;
      }

      this.noteIdx++;
      this.combo.total += 1;

      if (this.keyShuffle[mapping[note.k]] >= 0 && this.keyShuffle[mapping[note.k]] < this.keyMap.length) {
        let height = this.downNotePerSecond * ((ts - note.ts) / 1000);
        
        if (note.k >= 0 && note.k < 7) {
          this.notes[this.keyShuffle[mapping[note.k]]].push({y: height});
        }
        if (note.k >= 7 && note.k < 14) {
          this.notes[this.keyShuffle[mapping[note.k]]].push({y: height});
          this.longNoteSwitch[this.keyShuffle[mapping[note.k]]] = this.noteIdx;
        }
        if (note.k >= 14 && note.k < 21) {
          this.longNoteSwitch[this.keyShuffle[mapping[note.k]]] = 0;
        }
      }
    }

    if (this.recordedNotes.length && (this.recordedNotes[this.recordedNotes.length - 1].ts + 5000 < ts || !this.audio.context.isPlaying)) {
      this.recordedNotes = [];
      this.send_result();
    }
  }

  public init_visualize() {
    if (!this.audioSelector?.nativeElement) {
      setTimeout(this.init_visualize, 100);
    }
    else {
      const audioContext = new AudioContext();
      const audioSourceNode = audioContext.createMediaElementSource(this.audioSelector.nativeElement);
      const audioAnalyser = audioContext.createAnalyser();
      
      audioAnalyser.fftSize = 512;
      audioSourceNode.connect(audioAnalyser);
      audioSourceNode.connect(audioContext.destination);
      
      let frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
      let barSize = 2;
      let barMaxHeight = 70;
      let barHeightRatio = 255 / barMaxHeight;

      this.visualizationContext = this.visualizationCanvas.nativeElement.getContext('2d');
      this.visualizationContext.canvas.width = frequencyData.length * barSize;
      this.visualizationContext.canvas.height = barMaxHeight * 2 + 10;
    
      const renderFrame = () => {
        audioAnalyser.getByteFrequencyData(frequencyData);
        this.visualizationContext.clearRect(0, 0, this.visualizationContext.canvas.width, this.visualizationContext.canvas.height);
        
        this.visualizationContext.fillStyle = '#FFFFFF';
        this.visualizationContext.fillRect(0, this.visualizationContext.canvas.height / 2 - 1, this.visualizationContext.canvas.width * 0.7, 1);

        for (let i = 0; i < frequencyData.length; i++) {
          let height = frequencyData[i] / barHeightRatio;
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 - height, barSize, height);
        }
        
        this.visualizationContext.fillStyle = '#FFFFFF66';
        
        for (let i = 0; i < frequencyData.length; i++) {
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 + 5, barSize, frequencyData[i] / barHeightRatio);
        }

        this.visualizerHandle = requestAnimationFrame(renderFrame);  
      }

      renderFrame();
    }
  }

  public answer_check(i: number, j: number){
    let percent = Math.abs(this.answerLine - (this.notes[i][j].y / this.clientHeight));
    let bonusRange = this.downNotePerSecond * 0.00006
    let hitRange = 0.12 + bonusRange;
    let isLongnote = this.notes[i][j].longnote;
    let longnotePenalty = isLongnote ? 0.05 : 1;

    if (percent > hitRange) {
      this.combo.life = Math.max(this.combo.life - 2.0, 0);
      this.combo.lastJudge = 'Too Early!!';
      this.combo.lastClass = 'miss';
      this.combo.count = 0;
      this.score = Math.max(0, this.score - 200);
      this.gamelog.history.score.push({k: 1, s: this.score, t:this.audio.context.currentTime, l: this.combo.life});
    }
    else {
      let time = new Date().getTime();
      let score = Math.log(this.combo.count + 1) * (this.isShuffle ? 2 : 1) * (this.isFade ? 2 : 1);
      let answerType = 0;

      if (percent < hitRange * 0.4) {
        this.combo.life = Math.min(this.combo.life + 2.5 * longnotePenalty, 100);
        this.combo.lastJudge = 'Perfect!!';
        this.combo.lastClass = 'perfect';
        this.combo.perfect++;
        score *= 300;
        answerType = 5;
      } else if (percent < hitRange * 0.7) {
        this.combo.life = Math.min(this.combo.life + 1.5 * longnotePenalty, 100);
        this.combo.lastJudge = 'Excellent!';
        this.combo.lastClass = 'excellent';
        this.combo.excellent++;
        score *= 150;
        answerType = 4;
      } else if (percent < hitRange * 0.85) {
        this.combo.life = Math.min(this.combo.life + 0.5 * longnotePenalty, 100);
        this.combo.lastJudge = 'Good';
        this.combo.lastClass = 'good';
        this.combo.good++;
        score *= 100;
        answerType = 3;
      } else {
        this.combo.life = Math.max(this.combo.life - 2.0, 0.5);
        this.combo.lastJudge = 'Bad';
        this.combo.lastClass = 'bad';
        this.combo.bad++;
        score *= -100;
        answerType = 2;
      }

      this.combo.count += 1;
      this.combo.lastPivot = time;
      this.effectMap[i].lastHitPivot = time;
      this.effectMap[i].lastHitY = isLongnote ? this.answerLine * this.clientHeight : this.notes[i][j].y;
      this.effectMap[i].size = Math.max((1 - Math.abs(this.answerLine * this.clientHeight - this.notes[i][j].y) / this.clientHeight * 3), 0.1);
      this.score += score * longnotePenalty;
      this.scorePrint = this.score.toFixed(0);
      this.gamelog.history.score.push({k: answerType, s: this.score, t:this.audio.context.currentTime, l: this.combo.life});

      this.notes[i].splice(j, 1);

      this.keyPress = false;
    
      setTimeout(() => {
        this.keyPress = true;
      }, 10);

      if(this.combo.maxCombo < this.combo.count)
        this.combo.maxCombo = this.combo.count;
    }
  }


  public randomCreate() {
    if (Math.random() < 0.05) {
      let idx = Math.floor(Math.random() * this.keyMap.length);
      this.notes[idx].push({y: 0, py: 15});
      this.combo.total += 1;
    }
  }

  public downNote() {
    const time = new Date().getTime();
    const timeGap = (time - this.downNotePivot) / 1000;
    const py = this.downNotePerSecond * timeGap;
    const bonusRange = this.downNotePerSecond * 0.00006
    const hitRange = 0.12 + bonusRange;
    const hitHeight = this.clientHeight * hitRange;
    
    this.downNotePivot = time;

    if (this.isPaused)
      return;

    this.timebarWidth = this.audio.context.currentTime / this.audio.duration * 100;
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);

    
    for (let i = 0; i < this.notes.length; i++) {
      let gradientStyle = this.context.createLinearGradient(i * this.noteWidth, 0, (i + 1) * this.noteWidth, 0);
      
      gradientStyle.addColorStop(0.0, noteColorDark[i]);
      gradientStyle.addColorStop(0.5, noteColor[i]);
      gradientStyle.addColorStop(1.0, noteColorDark[i]);
      
      this.context.fillStyle = gradientStyle;
      this.context.strokeStyle = noteColor[i];
      this.context.lineWidth = 1;
      // this.context.fillStyle = noteColor[i];

      for (let j = 0; j < this.notes[i].length; j++) {
        this.notes[i][j].y += py;

        if (this.isFade) {
          this.context.fillStyle = noteColor[i] + ('00' + Math.max(Math.min(Math.floor(400 - (this.notes[i][j].y / this.clientHeight * 700)), 255), 0).toString(16)).substr(-2);
        }

        if (this.notes[i][j].longnote) {
          if (this.notes[i][j].longnote == -1) {
            this.context.fillStyle = '#666';
            this.context.fillRect(i * this.noteWidth, this.notes[i][j].y - py, this.noteWidth, py * 2);
            this.context.fillStyle = gradientStyle;
          }
          else {
            this.context.fillRect(i * this.noteWidth, this.notes[i][j].y - py, this.noteWidth, py * 2);    
          }
        }
        else {
          this.context.beginPath();
          this.context.moveTo(i * this.noteWidth, this.notes[i][j].y - hitHeight);
          this.context.lineTo(i * this.noteWidth, this.notes[i][j].y + hitHeight);
          this.context.moveTo((i + 1) * this.noteWidth, this.notes[i][j].y - hitHeight);
          this.context.lineTo((i + 1) * this.noteWidth, this.notes[i][j].y + hitHeight);
          this.context.stroke();
          this.context.fillRect(i * this.noteWidth, this.notes[i][j].y - 20, this.noteWidth, 20);
        }
        
        if (this.notes[i][j].y >= this.clientHeight * this.answerLine) {
          if (this.cheat) {
            this.onKeydown({code : this.keyMap[i]});
            this.onKeyup({code : this.keyMap[i]});
          }
          else if (this.notes[i][j].clear) {
            this.answer_check(i, j--);
          }
        }

        if (this.notes[i][j]?.longnote > 0 && this.effectMap[i].press && this.notes[i][j]?.y >= this.clientHeight * (this.answerLine - hitRange * 2)) {
          this.notes[i][j].clear = true;
        }
        else if (this.notes[i][j]?.y >= this.clientHeight){
          let isLongnote = this.notes[i][j].longnote;
          let noteId = this.notes[i][j].noteId;

          this.notes[i].splice(j--, 1);
          
          if (isLongnote != -1) {
            if (isLongnote == 1) {
              let k = 0
              
              for (; k < this.notes[i].length && this.notes[i][k].noteId == noteId; k++) {
                this.notes[i][k].longnote = -1;
              }

              if (k >= this.notes[i].length) {
                this.longNoteSwitch[i] *= -1;
              }
            }
            this.combo.life = Math.max(Math.min(this.combo.life - 5, 100), 0);
            this.combo.count = 0;

            this.combo.miss += 1;
            this.combo.lastJudge = 'Miss';
            this.combo.lastClass = 'miss';
            this.combo.lastPivot = new Date().getTime();
            this.score = Math.max(0, this.score - 200);
            this.scorePrint = this.score.toFixed(0);
            this.gamelog.history.score.push({k: -1, s: this.score, t:this.audio.context.currentTime, l: this.combo.life});

            console.log('combo')
            console.log(this.combo);
            if (this.combo.life <= 0) {
              this.send_fail();
            }
          }
        }
      }      
    }
  
    if (this.isDisplayEffect) {
      for (let i = 0; i < this.effectMap.length; i++) {
        if (this.isDisplayKeyPressEffect && (time - this.effectMap[i].keyPressPivot < 150 || this.effectMap[i].press)) {
          let gradientStyle = this.context.createLinearGradient((i + 0.5) * this.noteWidth, this.context.canvas.height * (this.answerLine - 0.35), (i + 0.5) * this.noteWidth, this.context.canvas.height);
          
          gradientStyle.addColorStop(0, effectColor[i] + '00');
          gradientStyle.addColorStop(this.answerLine - 0.25, effectColor[i] + '80');
          gradientStyle.addColorStop(1, effectColor[i] + 'C0');

          this.context.fillStyle = gradientStyle;
          
          this.context.fillRect(i * this.noteWidth, this.context.canvas.height * (this.answerLine - 0.35), this.noteWidth, this.context.canvas.height * (1.35 - this.answerLine));
        }
        if (this.isDisplayHitEffect && time - this.effectMap[i].lastHitPivot < 150){
          let idx = Math.floor((time - this.effectMap[i].lastHitPivot) / 15);

          this.context.drawImage(this.hitEffectImage,
            (idx % 5) * 192, Math.floor(idx / 5) * 192, 192, 192,
            (i - 2  * this.effectMap[i].size) * this.noteWidth, this.effectMap[i].lastHitY - this.noteWidth * 2.5  * this.effectMap[i].size, 
            this.noteWidth * 5 * this.effectMap[i].size, this.noteWidth * 5 * this.effectMap[i].size
          );
        }
        if (this.isDisplayHitEffect && time - this.effectMap[i].lastHitPivot < 800){
          let idx = Math.floor((time - this.effectMap[i].lastHitPivot) / 40) + 10;

          this.context.drawImage(this.hitEffectImage2,
            (idx % 5) * 192, Math.floor(idx / 5) * 192, 192, 192,
            (i - 1.5 * this.effectMap[i].size) * this.noteWidth, this.effectMap[i].lastHitY - this.noteWidth * 3 * this.effectMap[i].size, 
            this.noteWidth * 4 * this.effectMap[i].size, this.noteWidth * 4 * this.effectMap[i].size
          );
        }
      }
    }

    
  }
  
  public onReadyVideo() {
    this.video.play = this.videoSelector?.nativeElement?.play.bind(this.videoSelector.nativeElement);
    this.video.pause = this.videoSelector?.nativeElement?.pause.bind(this.videoSelector.nativeElement);
  }
  public onCanPlay() {
    this.downNotePivot = new Date().getTime();
    this.audio.paused = this.audioSelector?.nativeElement?.isPaused;
    this.audio.play = this.audioSelector?.nativeElement?.play.bind(this.audioSelector.nativeElement);
    this.audio.pause = this.audioSelector?.nativeElement?.pause.bind(this.audioSelector.nativeElement);
    this.audio.duration = this.audioSelector?.nativeElement?.duration;
    this.audio.context = this.audioSelector.nativeElement;

    const animate = () => {
      this.onPaintHandle = requestAnimationFrame(animate);
      this.createNote();
      this.downNote();
    }


    if (this.coverImage && this.audioURL) {
      try {
        this.init_visualize();
      }
      catch(err) {
        return;
      }
    }

    animate();
    this.play();
  }
  public send_result() {
    this.threeMMD.ready = false;

    setTimeout(()=>{
      if (this.onPaintHandle) {
        cancelAnimationFrame(this.onPaintHandle);
        this.onPaintHandle = undefined;
      }
  
      this.playService.send_result(this.gameId, this.mid, this.score, this.combo.maxCombo, this.combo.miss, this.combo.total, this.gamelog).subscribe((res:any)=>{
        this.zone.run(() => {
          let key = `${new Date().getTime()}`;

          console.log(`# Game Log`);
          console.log(this.gamelog);
                    
          this.playService.set_result(key, {
                rank: res.rank || 'Err',
                point: res.point || 0,
                score: this.score,
                combo: this.combo
          });
          this.router.navigate([`/result/${this.mid}/${key}`]);
        })
      })
    }, 4000)
  }

  public send_fail() {
    this.threeMMD.ready = false;
    
    if (this.onPaintHandle) {
      cancelAnimationFrame(this.onPaintHandle);
      this.onPaintHandle = undefined;
    }

    let key = `${new Date().getTime()}`;

    console.log(`# Game Log`);
    console.log(this.gamelog);

    this.playService.set_result(key, {
      rank: 'F',
      point: 0,
      score: this.score,
      combo: this.combo,
      log: this.gamelog
    });
    this.router.navigate([`/result/${this.mid}/${key}`]);
  }

  public async play(countdown: number = 3) {
    const animate = () => {
      this.countdown = countdown--;
      
      if (this.countdown <= 0) {
        this.audio.pivot = undefined;
        this.isPaused = false;
        
        if (typeof(this.audio?.play) == 'function') {
          this.audio.play();
        }
        if (typeof(this.video?.play) == 'function') {
          this.video.play();
        }

        if (this.pauseAnimationHandle) {
          clearInterval(this.pauseAnimationHandle);
          this.pauseAnimationHandle = undefined;
        }
      }
    }
    
    if (this.pauseAnimationHandle) {
      clearInterval(this.pauseAnimationHandle);
      this.pauseAnimationHandle = undefined;
    }
    
    this.pauseAnimationHandle = setInterval(animate, 1000);

    if (typeof(this.audio?.play) == 'function') {
      // if(this.audio.context.currentTime > 0) {
        this.isPaused = false;
        this.audio.play();
        await Tone.start();
        Tone.Transport.start();
        console.log('Tone; play')
      // }
    }
  }
  public async pause() {
    if (this.pauseAnimationHandle) {
      clearInterval(this.pauseAnimationHandle);
      this.pauseAnimationHandle = undefined;
    }

    if (typeof(this.audio?.pause) == 'function' && this.isPaused == false) {
      this.audio.pause();
      Tone.Transport.stop();
      console.log('Tone; pause')
      
      if (this.audio.pivot == undefined) {
        this.audio.pivot = this.audio.context.currentTime;
        console.log(this.audio.pivot);
      }
      
      console.log(this.audio)
      console.log(this.audio?.context?.currentTime);

      if (this.audio?.context?.currentTime) {
        this.audio.context.currentTime = this.audio.pivot - 4;
        Tone.Transport.seconds = this.audio.pivot - 4;
        // this.audio.context.currentTime = Math.max(this.audio.pivot - 4, 0);
        console.log(this.audio.context.currentTime);
        
        let gap = this.audio.pivot - this.audio.context.currentTime;
        console.log(gap);

        for (let i = 0; i < this.notes.length; i++) {
          for (let j = 0; j < this.notes[i].length; j++) {
            this.notes[i][j].y -= this.downNotePerSecond * gap;
          }
        }
      }
    }
    if (typeof(this.video?.pause) == 'function') {
      this.video.pause();
    }

    this.isPaused = true;
    this.countdown = -1;
  }

  public onLeave(event: any){
    this.pause();

    let data = {
      width : '450px',
      data : {
        title: 'Warning',
        content: `Do you want leave this page?`,
        closedReason : undefined,
        button : [
          { text: '👍', color: 'black' },
          { text: '❤️', color: 'black' },
          // { text: '🔥', color: 'black' },
          { text: 'Cancel', color: '#333' },
          { text: 'Retry', color: 'purple' },
          { text: 'Leave', color: 'red' }
        ],
      },
    };


    this.dialog.open(ConfirmDialogComponent, data).afterClosed().subscribe((res: string) => {
      switch (res) {
        case '👍':
        case '❤️':
        case '🔥':
          this.communityService.set_reaction(this.mid, res, this.audio.context.currentTime * 1000).subscribe((res)=>{
            console.log(res);
          })
          this.play();
          break;
        case 'Cancel':
          this.play();
          break;
        case 'Retry':
          this.init();
          break;
        case 'Leave':
          this.send_fail();
          break;
      }
    }, null, () => {
      console.log(data);

      if (!data.data.closedReason) {
        this.play();
      }
    });
  }
}
