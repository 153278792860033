import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserManagementComponent } from './page/user-management/user-management.component';
import { PostComponent } from './page/post/post.component';
import { LoginComponent } from './page/login/login.component';
import { SelectMusicComponent } from './page/select-music/select-music.component';
import { PlayComponent } from './page/play/play.component';
import { PlayNetworkComponent } from './page/play-network/play-network.component';
import { ResultPageComponent } from './page/result-page/result-page.component';
import { ShopComponent } from './page/shop/shop.component';
import { CraftNoteComponent } from './page/craft-note/craft-note.component';
import { CraftListComponent } from './page/craft-list/craft-list.component';
import { PointHistoryComponent } from './page/point-history/point-history.component';

import { MainMenuComponent } from './page/main-menu/main-menu.component';
import { MusicChoiceComponent } from './page/music-choice/music-choice.component';
import { MusicStoreComponent } from './page/music-store/music-store.component';
import { MusicResultComponent } from './page/music-result/music-result.component';
import { KalidoComponent } from './page/kalido/kalido.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'background', component: LoginComponent },
  { path: 'account', component: UserManagementComponent },
  { path: 'select', component: SelectMusicComponent },
  { path: 'play', component: PlayComponent },
  { path: 'play/:mid', component: PlayComponent },
  { path: 'play-network', component: PlayNetworkComponent },
  { path: 'play-network/:mid', component: PlayNetworkComponent},
  { path: 'result-page/:mid/:key', component: ResultPageComponent },
  { path: 'result/:mid/:key', component: MusicResultComponent },
  { path: 'shop', component: ShopComponent },
  { path: 'craft', component: CraftListComponent },
  { path: 'craft/:cmid', component: CraftNoteComponent },

  { path: 'menu', component: MainMenuComponent },
  { path: 'menu/:step', component: MainMenuComponent },
  { path: 'choice', component: MusicChoiceComponent },
  { path: 'choice/:tid', component: MusicChoiceComponent },
  { path: 'choice/:tid/:mid', component: MusicChoiceComponent },
  { path: 'shop-mk2', component: MusicStoreComponent },
  { path: 'point-log', component: PointHistoryComponent },
  { path: 'youtube', component: PostComponent },
  { path: 'chat', component: KalidoComponent },
  { path: 'chat/:roomid', component: PostComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
