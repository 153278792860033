import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { PlayService } from 'src/app/service/play.service';
import { UtilsService } from 'src/app/service/utils.service';
import { StatusService } from 'src/app/service/status.service';
import { GameManagerService } from 'src/app/service/game-manager.service';
import { AccountService } from 'src/app/service/account.service';
import { SettingService } from 'src/app/service/setting.service';
import { MMDService } from 'src/app/service/mmd.service';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  public choicePlayCategoryStep: boolean = false;
  public categoryList: Array<any> = [];
  public audioSrc: string | undefined;
  public mute: boolean = false;

  public bannerIdx: number = 0;
  public bannerTimeout: number = 6500;
  public bannerHandle: any = undefined;
  public banner: any = undefined;
  public bannerList = [{
    content: 'Get the <span class="sb-point"><i class="fa fa-star"></i> Bonus 10%</span> on weekend!',
    image: `/assets/images/event-banner-1-600x0.webp`,
    link: 'choice',
    linkType: 'navigation'
  }, {
    content: 'Earn <span class="sb-point">Point<i class="fa fa-star"></i></span> by watching ads!',
    image: `/assets/images/event-banner-6-600x0.webp`,
    linkType: 'ads'
  }, {
    content: 'New MMD model has been updated in the store!',
    image: `/assets/images/event-banner-5-600x0.webp`,
    link: 'shop',
    linkType: 'navigation'
  }, {
    content: 'Get <span class="sb-point"> 500<i class="fa fa-star"></i></span> & <span class="sb-ticket"><i class="fa fa-credit-card-alt"></i>3</span> everyday!',
    image: `/assets/images/event-banner-2-600x0.webp`,
    linkType: 'pointLog'
  }, {
    content: 'SoulBeat 1.2.6 Update Note!',
    image: `/assets/images/event-banner-4-600x0.webp`,
    link: 'https://naver.com',
    linkType: 'newTab'
  }]
  
  
  @ViewChild('visualizationCanvas') visualizationCanvas: ElementRef = {} as ElementRef;
  @ViewChild('audio') audioSelector: ElementRef = {} as ElementRef;

  private visualizationContext: CanvasRenderingContext2D = {} as CanvasRenderingContext2D;
  private visualizerHandle: any = undefined;
  
  private threeMMD: any = {}
  public loading: any = {};
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public accountService: AccountService,
    public setting: SettingService,
    public playService: PlayService,
    public utils: UtilsService,
    public status: StatusService,
    public gms: GameManagerService,
    public mmd: MMDService,
  ) { 
    this.status.sidebar = false;
    
    this.update_banner(0);
    
    const step = route.snapshot.params['step'] || 0;

    // switch(step) {
    //   case 'select_category':
    //     this.choicePlayCategoryStep = true;
    //     break;
    // }
  }

  ngOnInit(): void {
    this.playService.get_category().subscribe((res)=>{
      if(res.status) {
        this.categoryList = res.data;
        
        setTimeout(() => {
          this.mmd.init_stageMMD(
            'mmd-wrapper', this.threeMMD, this.setting.stage, this.loading, 
            this.setting.stage.model, this.setting.stage.pose, this.setting.stage.category, this.setting.stage.delay
          );
        }, 2000);
      }
    })
  }


  ngOnDestroy(): void {
    this.status.sidebar = true;
    
    cancelAnimationFrame(this.visualizerHandle);
    cancelAnimationFrame(this.threeMMD.fid);
  }

  public onFocus(event: any, item: any) {
    this.audioSrc = item.audio_url;
  }
  public onScroll(event: any) {
    console.log(event);
  }
  public close_selectCategory() {
    this.audioSrc = undefined;
    this.choicePlayCategoryStep = false;
  }

  public dialog_ads() {
    let volume = this.gms.setting.setting.volume;

    this.gms.setting.setting.volume = 0;

    this.gms.dialog_ads(() => {
      this.gms.setting.setting.volume = volume;
    });
  }

  public click_banner(banner: any) {
    switch (banner?.linkType) {
      case 'pointLog':
        this.gms.dialog_pointlog();
        break;
      case 'ticketLog':
        this.gms.dialog_ticketlog();
        break;
      case 'navigation':
        this.router.navigate([`/${banner?.link}`]);
        break;
      case 'ads':
        this.dialog_ads();
        break;
      case 'newTab':
        window.open(banner?.link, '#');
        break;
    }
  }
  public update_banner(offset: number) {
    if (this.bannerHandle) {
      clearTimeout(this.bannerHandle);
    }
    
    this.bannerIdx = (this.bannerIdx + this.bannerList.length + offset) % this.bannerList.length;
    this.banner = Object.assign({}, this.bannerList[this.bannerIdx]);
    this.banner.content = this.sanitizer.bypassSecurityTrustHtml(this.banner.content);
    this.banner.imageUrl = `url("${this.banner.image}")`;
    
    this.bannerHandle = setTimeout(()=> this.update_banner(1), this.bannerTimeout);
  }
  public navigate_shop() {
    this.mute = true;

    this.gms.dialog_shop(() => {
      this.mute = false;
    });
  }
  public navigate_craft() {
    this.mute = true;

    this.gms.dialog_craft(() => {
      this.mute = false;
    });
  }
  public navigate_stage() {
    this.mute = true;

    this.gms.dialog_stage(() => {
      this.mute = false;
    });
  }
  public navigate_choice(tid: string) {
    this.utils.lastCategory = tid;

    if (tid) {
      this.router.navigate([`/choice/${tid}`]);
    }
    else {
      this.router.navigate([`/choice`]);
    }
  }

  public pause() {
    this.setting.stage.music_play ? this.audioSelector?.nativeElement?.pause() : this.audioSelector?.nativeElement?.play();
    this.setting.stage.music_play = !this.setting.stage?.music_play;
  }


  public onCanPlay() {
    if (this.setting.stage?.music_play) {
      this.audioSelector.nativeElement.play();
    }
    this.init_visualization();
  }

  public init_visualization() {
    if (this.audioSelector?.nativeElement == undefined) {
      setTimeout(() => this.init_visualization(), 100);
    }
    else if(this.visualizerHandle == undefined) {
      const audioContext = new AudioContext();
        
      const audioSourceNode = audioContext.createMediaElementSource(this.audioSelector.nativeElement);
      const audioAnalyser = audioContext.createAnalyser();
      
      audioAnalyser.fftSize = 512;
      audioSourceNode.connect(audioAnalyser);
      audioSourceNode.connect(audioContext.destination);
      
      let frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount);
      let barSize = 2;
      let barMaxHeight = 70;
      let barHeightRatio = 255 / barMaxHeight;

      this.visualizationContext = this.visualizationCanvas.nativeElement.getContext('2d');
      this.visualizationContext.canvas.width = frequencyData.length * barSize;
      this.visualizationContext.canvas.height = barMaxHeight * 2 + 10;
    
      const renderFrame = () => {
        audioAnalyser.getByteFrequencyData(frequencyData);
        this.visualizationContext.clearRect(0, 0, this.visualizationContext.canvas.width, this.visualizationContext.canvas.height);
        this.visualizationContext.fillStyle = '#FFFFFF';

        for (let i = 0; i < frequencyData.length; i++) {
          let height = frequencyData[i] / barHeightRatio;
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 - height, barSize, height);
        }
        
        this.visualizationContext.fillStyle = '#FFFFFF33';
        
        for (let i = 0; i < frequencyData.length; i++) {
          this.visualizationContext.fillRect(i * barSize, this.visualizationContext.canvas.height / 2 + 5, barSize, frequencyData[i] / barHeightRatio);
        }

        this.visualizerHandle = requestAnimationFrame(renderFrame);  
      }

      renderFrame();
    }
  }
}
