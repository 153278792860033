import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PoseLandmarker, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';

declare let THREE: any;
declare let Ammo: any;


@Component({
  selector: 'app-kalido',
  templateUrl: './kalido.component.html',
  styleUrls: ['./kalido.component.scss']
})
export class KalidoComponent implements OnInit {
  @ViewChild('kalidoface') kalidoface: HTMLVideoElement = {} as HTMLVideoElement;
  @ViewChild('rendererContainer') rendererContainer!: ElementRef;

  private threeMMD: any = {};
  private mappingPoseLandmarker: any = {
    11: 'leftShoulder',
    12: 'rightShoulder',
    13: 'leftElbow',
    14: 'rightElbow',
    15: 'leftWrist',
    16: 'rightWrist',
    17: 'leftPinky',
    18: 'rightPinky',
    19: 'leftIndex',
    20: 'rightIndex',
    21: 'leftThumb',
    22: 'rightThumb',
    23: 'leftHip',
    24: 'rightHip',
    25: 'leftKnee',
    26: 'rightKnee',
    27: 'leftAnkle',
    28: 'rightAnkle',
    29: 'leftHeel',
    30: 'rightHeel',
    31: 'leftFootIndex',
    32: 'rightFootIndex',
    'leftShoulder': '左肩',
    'rightShoulder': '右肩',
    'leftElbow': '左ひじ',
    'rightElbow': '右ひじ',
    'leftWrist': '左手首',
    'rightWrist': '右手首',
    'leftPinky': '左小指先',
    'rightPinky': '右小指先',
    'leftIndex': '左人指先',
    'rightIndex': '右人指先',
    'leftThumb': '左親指先',
    'rightThumb': '右親指先',
    'leftHip': '腰キャンセル左',
    'rightHip': '腰キャンセル右',
    'leftKnee': '左ひざ',
    'rightKnee': '右ひざ',
    'leftAnkle': '左足首',
    'rightAnkle': '右足首',
    'leftHeel': '左足',
    'rightHeel': '右足',
    'leftFootIndex': '左つま先',
    'rightFootIndex': '右つま先',
  };

  constructor() { 
    
  }

  ngOnInit(): void {
    this.init();

    this.threeMMD.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
    this.threeMMD.camera.position.x = 0;
    this.threeMMD.camera.position.y = 20;
    this.threeMMD.camera.position.z = 40;
    this.threeMMD.camera.lookAt(new THREE.Vector3(0, 15, 0));

    this.threeMMD.scene = new THREE.Scene();
    this.threeMMD.scene.background = new THREE.Color(0x000000);


    const listener = new THREE.AudioListener();
    this.threeMMD.camera.add(listener);
    this.threeMMD.scene.add(this.threeMMD.camera);

    // const ambient = new THREE.AmbientLight(0x555555);
    // const ambient = new THREE.AmbientLight(0x888888);
    
    // this.threeMMD.scene.add(ambient);
    

    
    const directionalLight = new THREE.DirectionalLight(0xcccccc);
    // const directionalLight = new THREE.DirectionalLight(0x887766);
    
    // directionalLight.position.set(-1, 1, 1).normalize();
    directionalLight.position.set(150, 150, 150);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 5120;
    directionalLight.shadow.mapSize.height = 5120;
    // directionalLight.shadow.camera.near = 0.5;
    // directionalLight.shadow.camera.far = 500;

    this.threeMMD.scene.add(directionalLight);

    this.threeMMD.renderer = new THREE.WebGLRenderer({antialias: true});
    this.threeMMD.renderer.setPixelRatio(window.devicePixelRatio);
    this.threeMMD.renderer.setSize(window.innerWidth, window.innerHeight);
    this.threeMMD.renderer.shadowMap.enabled = true;
    
    document.getElementById('mmd-wrapper')?.appendChild(this.threeMMD.renderer.domElement);

    // # outline in mmd
    this.threeMMD.effect = new THREE.OutlineEffect(this.threeMMD.renderer);

    // 여기에서 3D 모델을 로드하고 초기화합니다.
    const modelPath = '../../../assets/models/mmd/haku/Tda式改変ハク・ワンピccv_bc1.01/Tda式改変ハク・ワンピccv_bc ver1.01.pmx';
    let mmdLoader = new THREE.MMDLoader();

    mmdLoader.load(modelPath, (object: any) => {
      object.traverse((child: any) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;    // 해당 메쉬가 그림자를 생성
          child.receiveShadow = true; // 해당 메쉬가 그림자를 받음
        }
      });

      for (let i = 0; i < object.skeleton.bones.length; i++) {
        object.skeleton.bones[i].idx = i;
      }
      
      
      this.threeMMD.mmdMesh = object;
      this.threeMMD.iks = {
        rightIndex: [{
          target: 127, // "右手先", 127
          effector: 125, // "右手首", 125
					iteration: 10,
          links: [],
        }],
      }

      const connect_links = (bone: any) => {
        if (bone?.idx != undefined && object.skeleton.bones[bone.idx].name.search('右') != -1) {
          console.log(`connect -> ${bone.idx}  `, bone, object.skeleton.bones[bone.idx]);
        
          this.threeMMD.iks.rightIndex[0].links.push({
            index: bone.idx,
          })
          connect_links(bone.parent);
        }
      }

      object.skeleton.bones[127].parent = null;
      connect_links(object.skeleton.bones[125].parent);
      console.log(object.skeleton.bones);

      this.threeMMD.scene.add(object);
      this.threeMMD.ikSolver = new THREE.CCDIKSolver(this.threeMMD.mmdMesh, this.threeMMD.iks.rightIndex);
    });

    this.animate();
  }

  animate() {
    requestAnimationFrame(() => this.animate());
    // 모델 업데이트 로직
	  this.threeMMD.ikSolver?.update();
    this.threeMMD.renderer?.render(this.threeMMD.scene, this.threeMMD.camera);
  }

  demosSection: any;
  imageBlendShapes: any;
  videoBlendShapes: any;
  faceLandmarker: any = undefined;
  runningMode: "IMAGE" | "VIDEO" = "IMAGE";
    
  enableWebcamButton: any;
  webcamRunning: Boolean = false;
  videoWidth = 480;
  imageContainers: any;

  video: any;
  canvasElement: any;
  canvasCtx: any;
    
  lastVideoTime = -1;
  results: any = undefined;
  drawingUtils: any;
  

  public init = async () => { 
    const demosSection = document.getElementById("demos");

    let poseLandmarker: PoseLandmarker;
    let runningMode: any = "IMAGE";
    let enableWebcamButton: any;
    let webcamRunning: Boolean = false;
    const videoHeight = "720px";
    const videoWidth = "1280px";

    const createPoseLandmarker = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
      );
      poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
          delegate: "GPU"
        },
        runningMode: runningMode,
        numPoses: 2
      });
      demosSection?.classList.remove("invisible");
    };
    createPoseLandmarker();


    const video = document.getElementById("webcam") as HTMLVideoElement;
    const canvasElement = document.getElementById(
      "output_canvas"
    ) as HTMLCanvasElement;
    const canvasCtx: any = canvasElement.getContext("2d");
    const drawingUtils = new DrawingUtils(canvasCtx);

    
    const enableCam = (event: any) => {
      if (!poseLandmarker) {
        console.log("Wait! poseLandmaker not loaded yet.");
        return;
      }

      if (webcamRunning === true) {
        webcamRunning = false;
        enableWebcamButton.innerText = "ENABLE PREDICTIONS";
      } else {
        webcamRunning = true;
        enableWebcamButton.innerText = "DISABLE PREDICTIONS";
      }

      const constraints = {
        video: true
      };

      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        video.srcObject = stream;
        video.addEventListener("loadeddata", predictWebcam);
      });
    }

    const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;

    if (hasGetUserMedia()) {
      enableWebcamButton = document.getElementById("webcamButton");
      enableWebcamButton.addEventListener("click", enableCam);
    } else {
      console.warn("getUserMedia() is not supported by your browser");
    }


    let lastVideoTime = -1;
    const predictWebcam = async () => {
      canvasElement.style.height = videoHeight;
      video.style.height = videoHeight;
      canvasElement.style.width = videoWidth;
      video.style.width = videoWidth;
      
      
      if (runningMode === "IMAGE") {
        runningMode = "VIDEO";
        await poseLandmarker.setOptions({ runningMode: "VIDEO" });
      }
      let startTimeMs = performance.now();
      if (lastVideoTime !== video.currentTime) {
        lastVideoTime = video.currentTime;
        poseLandmarker.detectForVideo(video, startTimeMs, (result) => {
          canvasCtx?.save();
          canvasCtx?.clearRect(0, 0, canvasElement.width, canvasElement.height);

          for (const landmark of result.landmarks) {            
            // 왼팔을 움직여보자
            if (this.threeMMD?.mmdMesh) {
              console.log(this.threeMMD.mmdMesh);

              // for (let i = 11; i <= 32 && i < landmark.length; i += 2) {
              for (let i = 16; i <= 16 && i < landmark.length; i += 2) {
                const armBone = this.threeMMD.mmdMesh.skeleton.getBoneByName(this.mappingPoseLandmarker[this.mappingPoseLandmarker[i]]);

                if (armBone) {
                  // armBone.position.x = landmark[i].x
                  // armBone.position.y = landmark[i].y
                  // armBone.position.z = landmark[i].z
                
                  this.threeMMD.mmdMesh.skeleton.bones[127].position.x = this.threeMMD.mmdMesh.skeleton.bones[111].position.x + (landmark[20].x - landmark[12].x) * 100;
                  this.threeMMD.mmdMesh.skeleton.bones[127].position.y = this.threeMMD.mmdMesh.skeleton.bones[111].position.y - (landmark[20].y - landmark[12].y) * 100;
                  this.threeMMD.mmdMesh.skeleton.bones[127].position.z = this.threeMMD.mmdMesh.skeleton.bones[111].position.z - (landmark[20].z - landmark[12].z);
                  // this.threeMMD.mmdMesh.skeleton.getBoneByName("右手先").position.z = landmark[i].z;

                  // this.threeMMD.ikSolver = new THREE.CCDIKSolver(this.threeMMD.mmdMesh, this.threeMMD.iks.rightIndex);
                  // const ccdikhelper = new THREE.CCDIKHelper(this.threeMMD.mmdMesh, this.threeMMD.iks, 0.01 );
                  // this.threeMMD.scene.add(ccdikhelper);
                  
                  // armBone.rotation.x = THREE.Math.degToRad(30); // 예시: X축으로 30도 회전
                  // armBone.rotation.y = THREE.Math.degToRad(45); // 예시: Y축으로 45도 회전
                  console.log(`[${this.mappingPoseLandmarker[this.mappingPoseLandmarker[i]]}] : (${landmark[i].x}, ${landmark[i].y}, ${landmark[i].z})  `, armBone)
                }
                else {
                  console.log(`not found [${this.mappingPoseLandmarker[this.mappingPoseLandmarker[i]]}]`);
                }
              }
            }

            // end;

            drawingUtils.drawLandmarks(landmark, {
              radius: (data) => DrawingUtils.lerp(data.from!.z, -0.15, 0.1, 5, 1)
            });
            drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
          }
          canvasCtx?.restore();
        });
      }

      if (webcamRunning === true) {
        window.requestAnimationFrame(predictWebcam);
      }
    }

  }
}
