import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';

import { PlayService } from 'src/app/service/play.service';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-ranking-dialog',
  templateUrl: './ranking-dialog.component.html',
  styleUrls: ['./ranking-dialog.component.scss']
})
export class RankingDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RankingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
    public play: PlayService,
    private status: StatusService,
  ) { 
    this.status.isOpenDialog = true;
    this.load_ranking(this.data, this.data.mid);
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }

  close(): void {
    this.status.isOpenDialog = false;
    this.dialogRef.close();
  }

  public load_ranking(data: any, mid: string) {
    if(data.ranking) {
      data.ranking = undefined;
    }
    else {
      this.play.load_ranking(mid).subscribe(res => {
        let ranking = res.data;
        let my = res.my;

        const parse_data = (data: Array<any>) => {
          for (let i = 0; i < data.length; i++) {
            data[i].accuracy = (+data[i].accuracy).toFixed(2);
            data[i].ts = data[i].ts.split('T')[0];
            data[i].rankClass = data[i].rank.replace('+', 'p').replace('-', 'm');
          }

          return data;
        }

        data.ranking = parse_data(ranking);
        data.my_ranking = parse_data(my);
      })
    }
  }

  public show_replay(replay_id: string) {
    this.play.load_replay(replay_id).subscribe((res: any) => {
      console.log(res);
    })
  }
}
