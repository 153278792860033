<link href="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css" rel="stylesheet" />
<script src="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.js"></script>

<div style="position: relative;">
  <div id="mmd-wrapper" style="position: absolute; width: 100vw; height: 100vh; left:0; right:0;"></div>
  <video id="webcam" style="width: 1280px; height: 720px; position: absolute; opacity: 0.5;" autoplay playsinline></video>
  <canvas class="output_canvas" id="output_canvas" width="1280" height="720" style="position: absolute; left: 0px; top: 0px;"></canvas>
</div>

<button id="webcamButton" class="mdc-button mdc-button--raised">
  <span class="mdc-button__ripple"></span>
  <span class="mdc-button__label">ENABLE WEBCAM</span>
</button>

